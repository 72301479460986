import React, {Component} from "react";
import 'fontsource-roboto';
import {fade, withStyles} from "@material-ui/core/styles";
import {Button, InputBase, Paper} from "@material-ui/core";
import {showModal} from "../../actions";
import {connect} from "react-redux";

const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    buttonPrimary: {
        margin: '5px',
        padding: '5px 25px',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.light.default,
        '&:hover': {
            backgroundColor: '#e85343',
        }

    },
    divider: {
        height: 28,
        margin: 4,
    },
});


class TextFieldCreateTopic extends Component {

    state = {
        addTopic: '',
        event_id: null,
        user: null
    }

    componentDidUpdate() {
        if (this.props?.user && this.state.user !== this.props.user) {
            this.setState({
                user: this.props.user
            })
        }
    }
    componentDidMount() {
        if (this.props?.user && this.state.user !== this.props.user) {
            this.setState({
                user: this.props.user
            })
        }
        if (localStorage.getItem("topic_title") !== null) {
            this.setTopicModal('modal-set-topic', localStorage.getItem("topic_title"))
        }
    }

    addTopic = (e) => {
        this.setState({addTopic: e.target.value})
    }

    // handleCreate = (data) => {
    //     if (this.state.user.isLogIn) {
    //         this.setTopicModal('modal-set-topic', data)
    //     } else {
    //         window.localStorage.setItem('topic_title', data);
    //         this.props.showModal('modal-login', '')
    //     }
    // }

    handleSubmit = (data, e) => {
        if (this.state.user.isLogIn) {
            this.setTopicModal('modal-set-topic', data, e)
        } else {
            e.preventDefault()
            window.localStorage.setItem('topic_title', data);
            this.props.showModal('modal-login', '')
        }
    }

    setTopicModal = (type, data, e) => {
        if (e) {
            e.preventDefault()
        }
        this.props.showModal(type, data)
        this.setState({addTopic: ''})
    }

    render() {
        const {classes} = this.props;
        return (
            <Paper
                component="form"
                   onSubmit={(e) => {
                       this.handleSubmit(this.state.addTopic, e)
                   }}
                   className={classes.root}>
                <InputBase
                    className={classes.input}
                    placeholder="(Optional) Share something you would like to discuss with a group. Please keep it professional."
                    inputProps={{'aria-label': 'Share something you would like to discuss with a group'}}
                    required
                    onChange={(e) => {
                        this.addTopic(e)
                    }}
                    value={this.state.addTopic}
                />
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonPrimary}
                    onClick={(e) => {
                        this.handleSubmit(this.state.addTopic, e)
                    }}
                >
                    create
                </Button>
            </Paper>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        user: state.user
    };
}

const mapDispatchToProps = dispatch => ({
    showModal: (type, data) => dispatch(showModal(type, data))
})

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(TextFieldCreateTopic));

