import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {
    Button,
    Checkbox,
    DialogContent, Divider, FormControlLabel, FormGroup, Grid, Link, TextField
} from "@material-ui/core";
import {withRouter} from "react-router";
import {closeModal, flag, showModal} from "../../actions";
import Typography from "@material-ui/core/Typography";
import LogoInit from "../Logo/LogoInit";
import {blue} from "@material-ui/core/colors";

const styles = theme => ({
    buttonSubmit: {
        margin: '5px',
        padding: '5px 25px',
        backgroundColor: blue[500],
        color: theme.palette.light.default,
        '&:hover': {
            backgroundColor: blue[300],
        }
    },
    buttonCancel: {
        margin: '5px',
        padding: '5px 25px',
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            padding: 0
        },
        '& textarea': {
            padding: '1rem 1.5rem'
        },
        '& textarea:focus + fieldset': {
            borderColor: `#ffffff !important`,
            borderWidth: 2,
        },
        '& textarea:focus:hover + fieldset': {
            borderColor: `#ffffff !important`,
            borderWidth: 2,
        }
    }
});


class ModalTopicFeedback extends Component {

    state = {
        modalTopic: this.props.modal.data.content,
        isTopicPublic: false,
        checkedData: null,
        feedbackContent: {
            comment: '',
            'unprofessional': false,
            'self-promotion': false,
            'political': false
        }
    }

    componentDidMount() {
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
        this.props.closeModal('modal-topic-feedback')
    };

    setFeedback = (e) => {
        const {value} = e.target;
        this.setState((state, props) => (
            {
                feedbackContent: {
                    ...state.feedbackContent,
                    comment: value
                },
            }
        ))
    }

    handleAreasOfConcernsCheckboxes = (e) => {

        const {name, checked} = e.target;

        this.setState((state, props) => (
            {
                feedbackContent: {
                    ...state.feedbackContent,
                    [name]: checked
                },
            }
        ))
    };

    showFeedbackResponseModal = (type, data) => {
        this.props.showModal(type, data)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    buildTopic = () => {
        let myObject = this.state.feedbackContent
        let topic_id = this.state.modalTopic.topicId;
        let comment = this.state.feedbackContent.comment;
        let types = [];

        for (var key in this.state.feedbackContent) {
            if (myObject.hasOwnProperty(key) && typeof myObject[key] === "boolean" && myObject[key] === true) {
                types.push(key)
            }
        }
        let formData = new FormData();


        // console.log(topic_id);
        // console.log(comment);
        // console.log(types);

        formData.append('topic_id', topic_id)
        formData.append('comment', comment)
        if (types && types.length > 0) {
            for (let i = 0; i < types.length; i++) {
                formData.append('type[]', types[i]);
            }
        }

        return formData
    }

    sendFeedback = () => {
        this.props.flag(this.buildTopic())
            .then(() => {
                this.showFeedbackResponseModal('modal-feedback-response', '')
            })
    }

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <DialogContent
                    className='modal__topic-feedback'>

                    <div style={{textAlign: 'center', margin: '20px 20px 30px'}}>
                        {/*SPARCMORE LOGO*/}
                        <LogoInit variant={'h4'}/>
                        {/* !SPARCMORE LOGO*/}
                    </div>

                    <Typography variant='body1'>
                        Thank you for helping to keep SPARCmore professional and respectful so that
                        meaningful conversations and communities can flourish.
                    </Typography>
                    <br/>
                    <Divider/>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Typography variant='body1'>
                                Author:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant='body1'>
                                {this.state.modalTopic.user}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{marginTop: '20px'}}>
                        <Grid item xs={2}>
                            <Typography variant='body1'>
                                Topic:
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant='body1'>
                                {this.state.modalTopic.topic}
                            </Typography>
                        </Grid>
                    </Grid>
                    <br/>
                    <Divider/>
                    <br/>

                    <Typography variant='body1'>
                        Select area of concern that you believe this conversation violates per the spirit
                        of SPARCmore’s <Link color='secondary' href='/terms-of-use' target='_blank'
                                             className='links-primary'>Terms of Use</Link> .
                    </Typography>
                    <div style={{marginTop: '30px'}}>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.feedbackContent.unprofessional}
                                                   onChange={(e) =>
                                                       this.handleAreasOfConcernsCheckboxes(e)
                                                   }
                                                   name="unprofessional"/>}
                                label="Unprofessional"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.feedbackContent['self-promotion']}
                                                   onChange={(e) =>
                                                       this.handleAreasOfConcernsCheckboxes(e)
                                                   }
                                                   name="self-promotion"/>}
                                label="Self Promotion"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.feedbackContent.political}
                                                   onChange={(e) =>
                                                       this.handleAreasOfConcernsCheckboxes(e)
                                                   }
                                                   name="political"/>}
                                label="Political"
                            />
                        </FormGroup>
                    </div>

                    <div className='modal__topic-feedback__textField'>
                        <TextField
                            id="outlined-multiline-static"
                            rows={3}
                            multiline
                            fullWidth
                            className={`${classes.textField}`}
                            value={this.state.feedbackContent.comment}
                            onChange={(e) => {
                                this.setFeedback(e)
                            }}
                            variant="outlined"
                        />

                    </div>

                    <div className='modal__topic-feedback__actions'>
                        <Button
                            variant="contained"
                            color='primary'
                            className={classes.buttonCancel}
                            onClick={this.closeModal}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.buttonSubmit}
                            onClick={() => {
                                this.sendFeedback();
                            }
                            }
                        >
                            Submit
                        </Button>
                    </div>

                </DialogContent>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

const mapDispatchToProps = dispatch => (
    {

        closeModal: data => dispatch(closeModal(data)),
        showModal: (type, data) => dispatch(showModal(type, data)),
        flag: (data) => dispatch(flag(data))
    }
)

export default withRouter(withStyles(styles,
    {
        withTheme: true
    }
)(connect(mapStateToProps, mapDispatchToProps)(ModalTopicFeedback)));
