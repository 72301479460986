import {
    FETCH_SET_TOPIC_MODAL,
    CLOSE_SET_TOPIC_MODAL,
    FETCH_NOT_FOUND_MODAL,
    CLOSE_NOT_FOUND_MODAL,
    FETCH_LOGIN_MODAL,
    CLOSE_LOGIN_MODAL,
    FETCH_TOPIC_FEEDBACK_MODAL,
    CLOSE_TOPIC_FEEDBACK_MODAL,
    FETCH_TOPIC_FEEDBACK_RESPONSE_MODAL,
    CLOSE_TOPIC_FEEDBACK_RESPONSE_MODAL,
    MODAL_LINAME,
    CLOSE_MODAL_LINAME,
    MODAL_EMAIL_SUBSCRIBERS,
    CLOSE_MODAL_EMAIL_SUBSCRIBERS
} from '../actions/types'

let INITIAL_STATE = {
    modalIsOpen: false,
    data: null,
    fullWidth: false,
    maxWidth: "md",
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_SET_TOPIC_MODAL:
            return {...state, data: action, fullWidth: true, maxWidth: "lg", modalIsOpen: true}
        case CLOSE_SET_TOPIC_MODAL:
            return {...state, data: action, modalIsOpen: false}
        case FETCH_NOT_FOUND_MODAL:
            return {...state, data: action, fullWidth: true, maxWidth: "md", modalIsOpen: true}
        case CLOSE_NOT_FOUND_MODAL:
            return {...state, data: action, modalIsOpen: false}
        case FETCH_TOPIC_FEEDBACK_MODAL:
            return {...state, data: action, fullWidth: true, maxWidth: "sm", modalIsOpen: true}
        case CLOSE_TOPIC_FEEDBACK_MODAL:
            return {...state, data: action, modalIsOpen: false}
        case FETCH_LOGIN_MODAL:
            return {...state, data: action, fullWidth: true, maxWidth: "md", modalIsOpen: true}
        case CLOSE_LOGIN_MODAL:
            return {...state, data: action, modalIsOpen: false}
        case FETCH_TOPIC_FEEDBACK_RESPONSE_MODAL:
            return {...state, data: action, fullWidth: true, maxWidth: "sm", modalIsOpen: true}
        case CLOSE_TOPIC_FEEDBACK_RESPONSE_MODAL:
            return {...state, data: action, modalIsOpen: false}
        case MODAL_LINAME:
            return {...state, data: action, fullWidth: true, maxWidth: "sm", modalIsOpen: true}
        case CLOSE_MODAL_LINAME:
            return {...state, data: action, modalIsOpen: false}
        case MODAL_EMAIL_SUBSCRIBERS:
            return {...state, data: action, fullWidth: true, maxWidth: "sm", modalIsOpen: true}
        case CLOSE_MODAL_EMAIL_SUBSCRIBERS:
            return {...state, data: action, modalIsOpen: false}
        default:
            return state;
    }
}
