import {combineReducers} from "redux";
import DrawerReducer from './DrawerReducer'
import TopicsReducer from './TopicsReducer'
import ModalReducer from './ModalReducer'
import TimeZonesReducer from './TimeZonesReducer'
import ConnectionsReducer from './ConnectionsReducer'
import UserAuthReducer from "./UserAuthReducer";
import UserProfileReducer from "./UserProfileReducer";
import TagsReducer from "./TagsReducer";
import MeetingsReducer from "./MeetingsReducer";
import UpcomingMeetingsReducer from "./UpcomingMeetingsReducer";
import PastMeetingsReducer from "./PastMeetingsReducer";
import NotifySubscribersReducer from "./NotifySubscribersReducer";
import ThemeReducer from "./ThemeReducer";


export default combineReducers({
    drawer: DrawerReducer,
    topics: TopicsReducer,
    modal: ModalReducer,
    tz: TimeZonesReducer,
    connections: ConnectionsReducer,
    user: UserAuthReducer,
    userProfile: UserProfileReducer,
    tags: TagsReducer,
    meeting: MeetingsReducer,
    upcomingMeetings: UpcomingMeetingsReducer,
    pastMeetings: PastMeetingsReducer,
    notifySubscribers: NotifySubscribersReducer,
    theme: ThemeReducer
})
