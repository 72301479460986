import React, {Component} from 'react';
import {fade} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import {withStyles} from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {drawerToggle, logOut, searchTopicsByWords, showModal, themeToggle} from "../../actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import LogoInit from "../Logo/LogoInit";
import {Divider, FormControlLabel, ListItem, Menu, MenuItem, Paper, Popper, Switch} from "@material-ui/core";
import {Link} from "react-router-dom";
import history from "../../history";
import {withSanctum} from "react-sanctum";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';


const styles = theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 2),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: '7px 0',
    },
});

class Header extends Component {

    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
        isMenuOpen: Boolean(this.anchorEl),
        isMobileMenuOpen: Boolean(this.mobileMoreAnchorEl),
        drawerIsOpen: null,
        menuEl: null,
        user: {},
        searchTopicsValue: '',
        lightModeIsOn: false
    }

    componentDidMount() {
        this.setState({
            drawerIsOpen: this.props.drawer.drawerIsOpen
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.userData !== this.state.user) {
            this.setState({
                user: this.props.userData
            })
        }
        if (this.props.drawer.drawerIsOpen !== this.state.drawerIsOpen) {
            this.setState({
                drawerIsOpen: this.props.drawer.drawerIsOpen
            })
        }

    }


    logout = () => {
        this.props.signOut().then(() => {
            this.props.checkAuthentication().then((response) => {
                if (!response) {
                    history.push('/')
                    window.location.reload();
                }
            })
        })
    }

    drawerToggle = (data) => {
        this.props.drawerToggle(data)
    }

    handleClickProfileMenu = (event) => {

        if (this.state.user.isLogIn) {
            this.setState({menuEl: event.currentTarget})
        } else {
            this.props.showModal('modal-login', '')
            this.handleCloseProfileMenu()
        }
    };

    searchTopicsValue = (e) => {
        this.setState({
            searchTopicsValue: e.target.value
        })
    }

    searchTopics = (e) => {
        e.preventDefault()
        history.push('/')
        this.props.searchTopicsByWords(this.state.searchTopicsValue)
    }

    handleCloseProfileMenu = () => {
        this.setState({menuEl: null})
    };

    handleLogout = () => {
        this.dispatchActionsToParent({type: 'logout'})
    }

    dispatchActionsToParent = (data) => {
        this.logout();
        this.handleCloseProfileMenu();
    }


    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    handleThemes = () => {
        this.setState({
            lightModeIsOn: !this.state.lightModeIsOn
        }, () => {
            this.props.themeToggle(this.state.lightModeIsOn)
        })
    }

    render() {
        const {classes} = this.props;

        return (
            <Toolbar>
                <div className="header-wrap">
                    <div className='header__menu-logo__wrap header-item'>
                        {/*BUTTON THAT OPEN DRAWER*/}
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => this.drawerToggle(!this.state.drawerIsOpen ? 'drawer-open' : 'drawer-close')}
                        >
                            <MenuIcon/>
                        </IconButton>
                        {/* !BUTTON THAT OPEN DRAWER*/}

                        {/*SPARCMORE LOGO*/}
                        <LogoInit variant={'h6'}/>
                        {/* !SPARCMORE LOGO*/}

                    </div>
                    {/* SEARCH BAR*/}
                    <Paper
                        component="form"
                        onSubmit={(e) => {
                            this.searchTopics(e)
                        }}
                        className={`${classes.search} header-item header__search__wrap`}>

                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{'aria-label': 'search'}}
                            onChange={(e) => {
                                this.searchTopicsValue(e)
                            }}
                        />
                        <Divider className={classes.divider} orientation="vertical"/>
                        <IconButton
                            className={classes.iconButton}
                            aria-label="search"
                            type="submit"
                            onSubmit={(e) => {
                                this.searchTopics(e)
                            }}
                        >
                            <SearchIcon/>
                        </IconButton>
                    </Paper>
                    {/* !SEARCH BAR*/}
                    {/* ACCOUNT ICON*/}
                    <div className={`${classes.sectionDesktop} header-item header__profile__wrap`}>
                            <div className='theme-toggle__wrap'>
                                <Brightness3Icon/>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.lightModeIsOn}
                                            onChange={() => {
                                                this.handleThemes()
                                            }}
                                            color="secondary"
                                            name="make topic public"
                                            inputProps={{'aria-label': 'public topic checkbox'}}/>}
                                    label=''
                                />
                                <WbSunnyIcon/>
                            </div>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            color="inherit"
                            // onClick={(e) => {
                            //     this.setTopicModal('modal-login', this.state.addTopic, e)
                            // }}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(e) => {
                                this.handleClickProfileMenu(e)
                            }}
                        >
                            <AccountCircle
                                fontSize="large"/>
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.menuEl}
                            keepMounted
                            open={Boolean(this.state.menuEl)}
                            onClose={() => {
                                this.handleCloseProfileMenu()
                            }}
                            getContentAnchorEl={null}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                            transformOrigin={{vertical: 'top', horizontal: 'center'}}
                        >
                            <MenuItem
                                component={Link} to='/profile'
                                onClick={() => {
                                    this.handleCloseProfileMenu()
                                }}
                            >My account</MenuItem>
                            <MenuItem onClick={() => {
                                this.handleLogout()
                            }}>Logout</MenuItem>
                        </Menu>
                    </div>


                    {/* ACCOUNT ICON*/}
                </div>

            </Toolbar>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        drawer: state.drawer,
        userData: state.user
    }
}

const mapDispatchToProps = dispatch => ({
    drawerToggle: data => dispatch(drawerToggle(data)),
    logOut: () => dispatch(logOut()),
    showModal: (type, data) => dispatch(showModal(type, data)),
    searchTopicsByWords: (data) => dispatch(searchTopicsByWords(data)),
    themeToggle: (data) => dispatch(themeToggle(data)),

})

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(withSanctum(Header))));
