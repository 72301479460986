import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {
    Button,
    DialogActions,
    DialogContent, FormControlLabel, Grid, Switch, TextField,
} from "@material-ui/core";
import {withRouter} from "react-router";
import {closeModal, createTopic, fetchTopics, updateTopic} from "../../actions";
import DatePickerComp from "../dateTimePickers/DatePickerComp";
import TimePicker from "../dateTimePickers/TimePicker";
import AutocompleteTagField from "../autocompleteField/AutocompleteTagField";
import Typography from "@material-ui/core/Typography";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import IconButton from "@material-ui/core/IconButton";
import {DateTime} from "luxon";
import history from "./../../history";

const styles = theme => ({
    textField: {
        '& .MuiOutlinedInput-root': {
            padding: 0
        },
        '& textarea': {
            padding: '1rem 1.5rem'
        },
        '& textarea:focus + fieldset': {
            borderColor: `#ffffff !important`,
            borderWidth: 2,
        },
        '& textarea:focus:hover + fieldset': {
            borderColor: `#ffffff !important`,
            borderWidth: 2,
        }
    },
    buttonPrimary: {
        margin: '5px',
        padding: '5px 25px',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.light.default,
        '&:hover': {
            backgroundColor: '#e85343',
        }

    },
});


class ModalContent extends Component {


    state = {
        isTopicPublic: true,
        title: '',
        start_at: null,
        end_at: null,
        user_id: this.props.userData.data.id,
        timezone: this.props.userData.data.timezone ? this.props.userData.data.timezone : this.props.userData.currentTimeZone,
        date: DateTime.local().setZone(this.props.userData.data.timezone),
        locationPath: this.props.location.pathname,
        tags: null,
        buttonType: null,
        topicId: null
    }

    componentDidMount() {
        //get info from updated topic
        let topic = this.props.modal.data.content
        if (typeof this.props.modal.data.content === "object") {
            this.setState({
                buttonType: 'update',
                title: topic.title,
                start_at: DateTime.fromISO(topic.start_at, {zone: this.state.timezone}),
                end_at: DateTime.fromISO(topic.end_at, {zone: this.state.timezone}),
                date: DateTime.fromISO(topic.start_at, {zone: this.state.timezone}).toISODate(),
                topicId: topic.id,
                defaultTags: topic.tags,
                updateEventMethod: 'PUT'
            })
        } else {
            this.setState({
                buttonType: 'create',
                title: topic,
                start_at: DateTime.local().setZone(this.state.timezone),
                end_at: DateTime.local().setZone(this.state.timezone),
                date: DateTime.local().setZone(this.state.timezone).toISODate()
            })
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
        this.props.closeModal('modal-set-topic')
        if (localStorage.getItem("topic_title") !== null) {
            localStorage.removeItem('topic_title');
        }
    };

    addTopicValue = (e) => {
        this.setState({title: e.target.value})
    }

    addTopicDate = (data) => {
        switch (data.type) {
            case 'startDate':
                return this.setState({start_at: data.date.setZone(this.state.timezone)})
            case 'endDate':
                return this.setState({end_at: data.date.setZone(this.state.timezone)})
            case 'date':
                return this.setState({date: data.date})
        }
    }

    isTopicPublic = () => {
        this.setState({isTopicPublic: !this.state.isTopicPublic})
    }

    formIsValid = () => {

        // console.log(DateTime.fromISO(this.state.end_at).toFormat('f') + ' >= ' + DateTime.local().toFormat('f'));
        // console.log(this.state.end_at >= DateTime.local());

        // && DateTime.fromISO(this.state.date) === DateTime.local()

        if (this.state.title && this.state.start_at < this.state.end_at && this.state.end_at > DateTime.local() && this.state.end_at >= DateTime.local()) {
            // if tha date is today
            return true
        } else return this.state.title && DateTime.fromISO(this.state.date) > DateTime.local() && this.state.start_at < this.state.end_at;

    }

    getTags = (tags) => {
        this.setState({tags: tags})
    }

    handleAutocompleteTagField = () => {
        if (this.state.buttonType === 'update' && this.state.defaultTags) {
            return (
                <AutocompleteTagField dispatchedTags={this.getTags}
                                      defaultTags={this.state.defaultTags}/>
            )
        } else if (this.state.buttonType === 'create') {
            return (
                <AutocompleteTagField dispatchedTags={this.getTags}/>
            )
        }
    }

    buildTopic = () => {
        let title = this.state.title;
        let start_at;
        let end_at;
        if (typeof this.state.date === "object") {
            start_at = this.state.date.toISODate() + ' ' + this.state.start_at.toFormat('HH:mm') + ', ' + this.state.timezone;
            end_at = this.state.date.toISODate() + ' ' + this.state.end_at.toFormat('HH:mm') + ', ' + this.state.timezone;
        } else {
            start_at = this.state.date + ' ' + this.state.start_at.toFormat('HH:mm') + ', ' + this.state.timezone;
            end_at = this.state.date + ' ' + this.state.end_at.toFormat('HH:mm') + ', ' + this.state.timezone;
        }


        let user_id = this.state.user_id;
        let tags = this.state.tags ? this.state.tags : this.state.defaultTags;
        let formData = new FormData();


        // console.log(typeof this.state.date);
        // console.log(start_at);
        // console.log(end_at);
        // console.log('defaultTags, ', this.state.defaultTags);
        // console.log('Tags, ',tags);

        formData.append('title', title)
        formData.append('user_id', user_id)
        formData.append('start_at', start_at)
        formData.append('end_at', end_at)

        if (this.state.buttonType === 'update') {
            formData.append('_method', this.state.updateEventMethod)
        }

        if (tags && tags.length > 0) {
            for (let i = 0; i < tags.length; i++) {
                if (typeof tags[i] === "string") {
                    formData.append('tags[]', tags[i]);
                } else {
                    formData.append('tags[]', tags[i].id);
                }
            }
        }

        return formData
    }

    createTopic = () => {
        // this.buildTopic()
        this.props.createTopic(this.buildTopic())
            .then((r) => {
                if (this.state.locationPath !== '/conversations') {
                    history.push('/conversations')
                }
            })
            .then(() => {
                window.location.reload()
            })

        this.closeModal()
    }

    updateTopic = () => {
        // this.buildTopic()
        this.props.updateTopic(this.buildTopic(), this.state.topicId)
            .then((r) => {
                if (this.state.locationPath !== '/conversations') {
                    history.push('/conversations')
                }
                // console.log(r);
            })
            .then(() => {
                window.location.reload()
            })

        this.closeModal()
    }


    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <DialogContent
                    className='modal__set-topic'>

                    {
                        this.state.buttonType === 'create'
                            ?
                            <Typography variant='h5'>
                                Create a NEW conversation room
                            </Typography>
                            :
                            <Typography variant='h5'>
                                Update conversation room
                            </Typography>
                    }

                    <Grid
                        container
                        spacing={2}
                        className='modal__set-topic__content__wrap'
                    >
                        <Grid item xs={6}>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={2}
                                className={`${classes.textField} w-100`}
                                value={this.state.title}
                                onChange={this.addTopicValue}
                                variant="outlined"
                            />

                        </Grid>
                        <Grid item xs={6}>
                            {
                                this.state.start_at && this.state.end_at
                                    ?
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid item md={4}>
                                            <DatePickerComp
                                                datePickerData={this.addTopicDate}
                                                type='date'
                                                date={this.state.date}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <TimePicker
                                                timePickerData={this.addTopicDate}
                                                type='startDate'
                                                time={this.state.start_at}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <TimePicker
                                                timePickerData={this.addTopicDate}
                                                type='endDate'
                                                time={this.state.end_at}
                                            />
                                        </Grid>
                                    </Grid>
                                    :
                                    null
                            }
                        </Grid>
                    </Grid>
                    <Typography variant='body1'>
                        Tags
                    </Typography>
                    <Grid
                        container
                        spacing={2}
                        className='modal__set-topic__content__wrap mt-2 mb-24'
                    >
                        <Grid item xs={6}>
                            {this.handleAutocompleteTagField()}

                        </Grid>
                        <Grid item xs={6}>
                            <DialogActions>
                                <div className='modal__set-topic__control-buttons'>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.isTopicPublic}
                                                    // onChange={() => {
                                                    //     this.isTopicPublic()
                                                    // }}
                                                    color="secondary"
                                                    name="make topic public"
                                                    inputProps={{'aria-label': 'public topic checkbox'}}/>}
                                            label="Public"
                                        />
                                    </div>
                                    <div>

                                        {
                                            this.state.buttonType === 'create'
                                                ?
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!this.formIsValid()}
                                                    className={classes.buttonPrimary}
                                                    onClick={() => {
                                                        this.createTopic()
                                                    }}
                                                >
                                                    create
                                                </Button>
                                                :
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!this.formIsValid()}
                                                    className={classes.buttonPrimary}
                                                    onClick={() => {
                                                        this.updateTopic()
                                                    }}
                                                >
                                                    update
                                                </Button>
                                        }
                                        <IconButton
                                            onClick={this.closeModal}
                                            aria-label="delete"
                                            className={classes.margin}>
                                            <CancelOutlinedIcon fontSize="large"/>
                                        </IconButton>
                                    </div>
                                </div>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        userData: state.user,
        topics: state.topics
    }
}

const mapDispatchToProps = dispatch => ({
    closeModal: data => dispatch(closeModal(data)),
    createTopic: data => dispatch(createTopic(data)),
    fetchTopics: data => dispatch(fetchTopics(data)),
    updateTopic: (data, topicId) => dispatch(updateTopic(data, topicId))
})

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(ModalContent)));
