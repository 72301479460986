import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {drawerToggle, showModal} from "../../actions";
import {connect} from "react-redux";
import {Divider, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import HomeIcon from '@material-ui/icons/Home';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ForumIcon from '@material-ui/icons/Forum';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import history from "../../history";
import Typography from "@material-ui/core/Typography";
import {DateTime} from "luxon";

const drawerWidth = 240;

const styles = theme => ({
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: theme.palette.primary.main
    },
    drawerIcon: {
        color: theme.palette.link.main
    }
});

class DrawerContent extends Component {

    state = {
        page: '',
        drawerIsOpen: this.props.drawer.drawerIsOpen,
        user: {},
        thisYear: DateTime.local().toFormat('yyyy')
    }

    componentDidMount() {
        this.setState({
            page: this.props.location.pathname,
            drawerIsOpen: this.props.drawer.drawerIsOpen,
            user: this.props.userData
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.userData !== this.state.user) {
            this.setState({
                user: this.props.userData
            })
        }
        if (this.props.location.pathname !== this.state.page) {
            this.setState({page: this.props.location.pathname})
        }

        if (this.props.drawer.drawerIsOpen !== this.state.drawerIsOpen) {
            this.setState({drawerIsOpen: this.props.drawer.drawerIsOpen})
        }
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    handleActions = (data, e) => {
        e.preventDefault()
        if (this.state.user.isLogIn) {
            history.push(data)
        } else {
            this.props.showModal('modal-login', '')
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <Drawer
                open={this.state.drawerIsOpen}
                className={`${classes.drawer} drawer__wrap`}
                variant="persistent"
                anchor="left"
                classes={{
                    paper: classes.drawer,
                }}
            >
                <div className={classes.drawerHeader}>
                </div>
                <List>
                    <ListItem button component={NavLink} activeClassName='Mui-selected' to='/' exact={true}>
                        <ListItemIcon>
                            <HomeIcon className={classes.drawerIcon}/>
                        </ListItemIcon>
                        <ListItemText primary={'Home'}/>
                    </ListItem>

                    <ListItem button component={NavLink} to='/subscribed' activeClassName='Mui-selected'
                              onClick={(e) => {
                                  this.handleActions('/subscribed', e)
                              }}
                    >
                        <ListItemIcon>
                            <SubscriptionsIcon className={classes.drawerIcon}/>
                        </ListItemIcon>
                        <ListItemText primary={'Subscribed List'}/>
                    </ListItem>

                    <ListItem button component={NavLink} activeClassName='Mui-selected'
                              to='/connections'
                              onClick={(e) => {
                                  this.handleActions('/connections', e)
                              }}
                    >
                        <ListItemIcon>
                            <GroupAddIcon className={classes.drawerIcon}/>
                        </ListItemIcon>
                        <ListItemText primary={'Connections'}/>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem button component={NavLink} activeClassName='Mui-selected'
                              to='/conversations'
                              onClick={(e) => {
                                  this.handleActions('/conversations', e)
                              }}
                    >
                        <ListItemIcon>
                            <ForumIcon className={classes.drawerIcon}/>
                        </ListItemIcon>
                        <ListItemText primary={'My Conversations'}/>
                    </ListItem>

                    <ListItem button component={NavLink} activeClassName='Mui-selected'
                              to='/dashboard'
                              onClick={(e) => {
                                  this.handleActions('/dashboard', e)
                              }}
                    >
                        <ListItemIcon>
                            <TrendingUpIcon className={classes.drawerIcon}/>
                        </ListItemIcon>
                        <ListItemText primary={'Dashboard'}/>
                    </ListItem>
                </List>
                <List className='drawer__links'>
                    <ListItem component={NavLink} className='drawer__links-item' activeClassName='Mui-selected'
                              to='/terms-of-use'>
                        <ListItemText
                            primary={'Terms of Services'}
                            className={classes.drawerIcon}
                        />
                    </ListItem>
                    <ListItem component={NavLink} className='drawer__links-item' activeClassName='Mui-selected'
                              to='/privacy-policy'>
                        <ListItemText
                            primary={'Privacy Policy'}
                            className={classes.drawerIcon}
                        />
                    </ListItem>
                    <ListItem component={NavLink} className='drawer__links-item disabled' disabled
                              activeClassName='Mui-selected' to='/contact-us'>
                        <ListItemText
                            primary={'Contact Us'}
                            className={classes.drawerIcon}
                        />
                    </ListItem>
                    <Typography style={{padding: '0.5rem 1rem'}}>
                        Copyright {this.state.thisYear} C2C Labs LLC
                    </Typography>
                </List>
            </Drawer>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        drawer: state.drawer,
        userData: state.user
    }
}

const mapDispatchToProps = dispatch => ({
    drawerToggle: data => dispatch(drawerToggle(data)),
    showModal: (type, data) => dispatch(showModal(type, data))
})

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(DrawerContent)));
