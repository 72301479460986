import React from 'react';
import {useJitsi} from 'react-jutsu';


function JitsiVideo({room, name, password, userRole, sessionId, eventNumber, eventName, sessionSponsorURL, sessionSponsorImageLink}) {

    const roomName = eventNumber
    let validateSessionSponsorURL;
    const parentNode = 'jitsi-container'
    const interfaceConfigOverwrite = {
        TOOLBAR_BUTTONS: userRole === 'host' ? [
            'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
            'fodeviceselection', 'hangup', 'profile', 'chat',
            'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
            'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
            'tileview', 'videobackgroundblur', 'help', 'recording', 'download'
        ] : [
            'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
            'fodeviceselection', 'hangup', 'chat', 'sharedvideo', 'raisehand',
            'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
            'tileview', 'videobackgroundblur', 'help'
        ],
        SHOW_CHROME_EXTENSION_BANNER: false,
        DEFAULT_REMOTE_DISPLAY_NAME: name,
    }
    const configOverwrite = {
        enableWelcomePage: false,
        // requireDisplayName - require to add name
        requireDisplayName: false,
        useNicks: true,
        enableCalendarIntegration: userRole === 'host',
        prejoinPageEnabled: false,
        disableProfile: userRole !== 'host',
        displayName: name,
        subject: 'SPARCmore',
        remoteVideoMenu: {
            // If set to true the 'Kick out' button will be disabled.
            disableKick: userRole !== 'host'
        },
    }
    const jitsiConfig = {
        roomName: 'konoha',
        password: 'dattebayo',
        subject: 'fan',
        parentNode: 'jitsi-container',
        // displayName: name,
    };


    const jitsi = useJitsi({jitsiConfig, name, roomName, parentNode, configOverwrite, interfaceConfigOverwrite})

    // useEffect(() => {
    //     if (jitsi) {
    //         console.log(jitsi);
    //         jitsi.addEventListener('videoConferenceJoined', () => {
    //             jitsi.executeCommand('displayName', name)
    //             jitsi.executeCommand('password', password)
    //             jitsi.executeCommand('subject', room)
    //             if (userRole === 'attendee') {
    //                 setInterval(function () {
    //                     jitsi.executeCommand('displayName', name)
    //                 }, 1000)
    //             }
    //
    //         })
    //     }
    //     return () => jitsi && jitsi.dispose()
    // }, [jitsi])

    function validateURL(link){
            if (link.indexOf("http://") === 0 || link.indexOf("https://") === 0) {
                return link
            }
            else{
                return `http://${link}`
            }
    }


    return (
        <React.Fragment>
            <div className='over-the-footer'
                  style={{zIndex: '999', position: 'relative'}}>
                <div className='jitsi__wrap'>
                    <div className='organizer__jitsi-title__wrap'>

                        {
                            sessionSponsorImageLink
                                ?
                                <div className='sponsor-img__wrap'>
                                    <a href={sessionSponsorURL ? validateURL(sessionSponsorURL) : '/'} target='_blank' rel="noopener noreferrer">
                                        <img src={sessionSponsorImageLink} alt={sessionSponsorURL}/>
                                    </a>
                                </div>
                                :
                                null
                        }
                    </div>

                    {/*<div id={parentNode} style={{width: '100%', height: '600px'}}/>*/}
                    <div id={jitsiConfig.parentNode} style={{width: '100%', height: '75vh'}}/>
                </div>
            </div>
            {/*<Grid item className={`${userRole} over-the-footer`} xs={12} sm={12} md={userRole === 'organizer' ? 3 : 2}*/}
            {/*      style={userRole === 'organizer' ? {marginTop: '0'} : {marginTop: '3rem'}}>*/}
            {/*    <div className='user-li-list'>*/}
            {/*        <ListLiveUsers sessionId={sessionId} sessionName={room} eventName={eventName}/>*/}
            {/*    </div>*/}
            {/*</Grid>*/}
        </React.Fragment>

    )
}

export default JitsiVideo;
