import React, {Component} from "react";
import 'fontsource-roboto';
import Typography from '@material-ui/core/Typography';
import {Avatar, Button, Grid, Link, TextField} from "@material-ui/core";
import avatarMan from "./../../assets/images/avatar-man.png"
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {fetchTimeZones, updateProfile} from "../../actions";
import {Autocomplete} from "@material-ui/lab";
import {blue} from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

const styles = theme => ({
    link: {
        color: theme.palette.link.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    buttonPrimary: {
        backgroundColor: blue[500],
        color: theme.palette.light.default
    }
})

class ProfilePage extends Component {

    state = {
        //tz returns list of timezones and the time zone of the device
        tz: null,
        currentTimeZone: null,
        name: '',
        email: '',
        image_link: '',
        timezone: '',
        linkedin_url: '',
        isProfileDataChanged: false,
        setUpdate: false
    }

    componentDidMount() {
        this.setUserInformation()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setUserInformation()
    }

    setUserInformation = () => {
        if (this.props.userData.data.name !== this.state.name) {
            this.props.fetchTimeZones()
                .then(() => {
                    this.setState({
                        name: this.props.userData.data.name,
                        email: this.props.userData.data.email,
                        image_link: this.props.userData.data.image_link ? this.props.userData.data.image_link : avatarMan,
                        linkedin_url: this.props.userData.data.linkedin_url ? this.props.userData.data.linkedin_url : '',
                        tz: this.props.tz.options
                    })
                })
                .then(() => {
                    if (!this.state.timezone) {
                        this.setState({
                            timezone: this.setTimeZoneOfTheUser(),
                        })
                    }

                })
        }
    }

    setTimeZoneOfTheUser = () => {

        if (this.props.userData.data.timezone) {
            return this.props.tz.options.find(tz => tz.tz === this.props.userData.data.timezone)

        } else if (!this.props.userData.data.timezone && this.props?.tz !== this.state.tz) {
            return this.props.tz.currentTimeZone
        }
    }


    updateTimeZone = (data) => {
        this.setState({
            timezone: data
        })
    }

    updateLI = (e) => {
        this.setState({
            linkedin_url: e.target.value
        })
    }

    submitProfileData = () => {
        this.props.updateProfile({
            social_account_url: this.state.linkedin_url,
            provider_name: 'linkedin',
            timezone: this.state.timezone ? this.state.timezone.tz : this.state.currentTimeZone.tz
        })
            .then(() => {
                window.location.reload();
            })
    }

    handleUpdateLI = () => {
        const {classes} = this.props;
        if (!this.state.setUpdate) {
            return (
                <React.Fragment>
                    <Typography variant='h6' className='profile__wrap__content-info__desc'>
                        <Link className={classes.link}
                              href={`https://www.linkedin.com/in/${this.state.linkedin_url}`}>
                            {`https://www.linkedin.com/in/${this.state.linkedin_url}`}
                        </Link>
                    </Typography>
                    <IconButton
                        onClick={() => {
                            this.setState({setUpdate: true})
                        }}
                        aria-label="edit">
                        <EditIcon/>
                    </IconButton>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Typography variant='h6' className='profile__wrap__content-info__desc'>
                        <Link className={classes.link}
                              href='#'>
                            https://www.linkedin.com/in/
                        </Link>
                    </Typography>
                    <TextField
                        onChange={this.updateLI}
                        value={this.state.linkedin_url}
                        style={{fontSize: '20px'}}
                        id="liURL"/>
                    <IconButton
                        onClick={() => {
                            this.setState({setUpdate: false})
                        }}
                        aria-label="cancel">
                        <CheckIcon/>
                    </IconButton>
                </React.Fragment>
            )
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div className='profile__wrap'>
                <Grid container justify={"center"}>
                    <Grid item md={12} lg={10}>
                        <div className='profile__wrap__content'>
                            <Avatar alt={this.state.name} src={this.state.image_link} className='profile__wrap__content-avatar'/>
                            <Typography variant='h4' className='profile__wrap__content-name'>
                                {this.state.name}
                            </Typography>
                            <div className='profile__wrap__content-info'>
                                <Grid container justify={"center"}>
                                    <Grid item xs={12} md={4} lg={2}>
                                        <Typography variant='h5' className=''>
                                            Email
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <Typography variant='h6' className='profile__wrap__content-info__desc'>
                                            <Link className={classes.link} href={`mailto:${this.state.email}`}>
                                                {this.state.email}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justify={"center"} alignItems={'center'}>
                                    <Grid item xs={12} md={4} lg={2}>
                                        <Typography variant='h5' className=''>
                                            LinkedIn URL
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className='d-flex justify-flex-end align-center'>
                                            {this.handleUpdateLI()}
                                        </div>
                                        {
                                            !this.state.linkedin_url
                                                ?
                                                <div  style={{textAlign: 'right'}}>
                                                    <Typography variant={"caption"} color={"secondary"}>
                                                        Please fill your linkedin address
                                                    </Typography>
                                                </div>
                                                :
                                                null
                                        }

                                    </Grid>
                                </Grid>
                                <Grid container justify={"center"}>
                                    <Grid item xs={12} md={4} lg={2}>
                                        <Typography variant='h5' className=''>
                                            Time Zone
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className='profile__wrap__content-info__desc'>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={this.state.tz ? this.state.tz : []}
                                                getOptionLabel={(option) => option.text ? option.text : ''}
                                                value={this.state.timezone ? this.state.timezone : this.state.currentTimeZone}
                                                getOptionSelected={(option, value) => option.text === value.text}
                                                onChange={(event, value) =>
                                                    this.updateTimeZone(value)
                                                }
                                                renderInput={(params) => <TextField {...params}
                                                                                    label="Choose your TimeZone"
                                                                                    variant="outlined"/>}
                                            />

                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container justify={"center"}>
                                    <Grid item xs={12} md={4} lg={2}/>
                                    <Grid item xs={12} md={6} lg={5}>
                                        <div className='d-flex justify-flex-end'>
                                            <Button
                                                variant="contained"
                                                className={classes.buttonPrimary}
                                                disabled={!this.state.linkedin_url}
                                                onClick={() => {
                                                    this.submitProfileData()
                                                }}
                                            >
                                                update profile information
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        tz: state.tz,
        userData: state.user,
        userProfile: state.userProfile
    };
}

const mapDispatchToProps = dispatch => ({
    fetchTimeZones: () => dispatch(fetchTimeZones()),
    updateProfile: data => dispatch(updateProfile(data))
})

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(ProfilePage)));
