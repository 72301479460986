import React, {Component} from "react";
import 'fontsource-roboto';
import {withStyles} from "@material-ui/core/styles";
import { InputBase, Paper} from "@material-ui/core";
import {searchConnections} from "../../actions";
import {connect} from "react-redux";

const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    buttonPrimary: {
        margin: '5px',
        padding: '5px 25px',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.light.default,
        '&:hover': {
            backgroundColor: '#e85343',
        }

    },
    divider: {
        height: 28,
        margin: 4,
    },
});


class TextFieldSearchConnections extends Component {

    state = {
        searchedWord: ''
    }

    componentDidMount() {
    }

    searchConnections = (e) => {
        this.props.searchConnections(e.target.value);
        this.setState({
            searchedWord: e.target.value
        })
    }


    render() {
        const {classes} = this.props;
        return (
            <Paper className={classes.root}>
                <InputBase
                    className={classes.input}
                    placeholder="Search Connection or Conversation"
                    inputProps={{'aria-label': 'Search Connection or Conversation'}}
                    required
                    onChange={(e) => {
                        this.searchConnections(e)
                    }}
                    value={this.state.searchedWord}
                />
            </Paper>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        // connections: state.connections
    };
}

const mapDispatchToProps = dispatch => ({
    searchConnections: (data) => dispatch(searchConnections(data))
})

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(TextFieldSearchConnections));

