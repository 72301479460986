import React, {Component} from "react";
import 'fontsource-roboto';
import {AppBar, Tab, Tabs} from "@material-ui/core";
import PropTypes from "prop-types";
import {fade, withStyles} from "@material-ui/core/styles";
import { fetchTopics, searchTopicsByTagID} from "../../actions";
import {connect} from "react-redux";
import {withSanctum} from "react-sanctum";
import {withRouter} from "react-router";

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    tagButton: {
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.5),
            cursor: 'pointer'
        },
    }

});

class ScrollableTabs extends Component {

    state = {
        value: 0,
        tags: this.props.tags.data,
        user: this.props.userData
    }

    componentDidUpdate() {
        if (this.props.userData && this.props.userData !== this.state.user) {
            this.setState({user: this.props.userData})
        }
        if (this.props.tags?.data && this.state.tags !== this.props.tags?.data) {
            this.setState({
                tags: this.props.tags.data
            })
        }
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    static TabPanel = {
        propTypes: {
            children: PropTypes.node,
            index: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired,
        }
    };

    a11yProps = (index) => {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    fetchAllTopics = () => {
        if (this.state.user.isLogIn) {
            this.props.fetchTopics(this.state.user.data.id)
        } else {
            this.props.fetchTopics()
        }
    }

    fetchTopicsByTagID = (tagId) => {
        this.props.searchTopicsByTagID(tagId)
    }

    render() {

        const {classes} = this.props;

        return (
            <div className={`${classes.root} scrollable-tabs__wrap`}>
                <AppBar position="static">
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab
                            onClick={() => {
                                this.fetchAllTopics()
                            }}
                            label='All'
                            key={'All'}
                            className='MuiChip-root'
                            {...this.a11yProps(0)} />
                        {
                            this.state.tags
                                ?
                                this.state.tags.map((tag, index) => {
                                    return (
                                        <Tab
                                            onClick={() => {
                                                this.fetchTopicsByTagID(tag.id)
                                            }}
                                            label={tag.name}
                                            key={tag.slug}
                                            className={`MuiChip-root ${classes.tagButton}`}
                                            {...this.a11yProps(index)}
                                        />
                                    )
                                })
                                :
                                null
                        }
                   </Tabs>
                </AppBar>
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        tags: state.tags,
        userData: state.user
    };
}

const mapDispatchToProps = dispatch => ({
    // drawerToggle: data => dispatch(drawerToggle(data))
    fetchTopics: (data) => dispatch(fetchTopics(data)),
    searchTopicsByTagID: (tagId) => dispatch(searchTopicsByTagID(tagId)),
})

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(withSanctum(withRouter(ScrollableTabs))));


