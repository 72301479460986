import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';

import {connect} from "react-redux";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import pusher from "pusher-js"
import axios from "axios";
import Echo from "laravel-echo";
import {Divider, InputBase, Paper} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {fade, withStyles} from "@material-ui/core/styles";
import {joinMeeting} from "../../actions";
import {withSanctum} from "react-sanctum";

const styles = theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        width: '100%',
        display: 'flex',
        alignItems: "center"
    },
    input: {
        width: '100%',
        padding: theme.spacing(1, 1, 1, 2),
    },
    divider: {
        height: 28,
        margin: '7px 0',
    },
})

class ListLiveUsers extends Component {

    state = {
        areAttendeesHere: false,
        attendees: [],
        attendeeLive: [],
        eventId: null,
        search: '',
        sortAttendees: false,
        selectedSortingButton: false,
        eventName: '',
        user: null,
        meeting: null
    }

    componentDidMount() {

        if (this.props.meeting && this.props.userData) {
            this.setState({
                meeting: this.props.meeting,
                user: this.props.userData
            }, () => {
                // join trough Meeting.js and here we re-join the user
                // re-join to the meeting.
                // In this way the first user can see its avatar, name and li into live-users list
                // re-join prerender the user list
                this.props.joinMeeting({topic_id: this.state.meeting.id})
            })
            window.echo = new Echo({
                broadcaster: "pusher",
                key: 'c21abc863830ea8c1fad',
                cluster: "eu",
                forceTLS: true,
                encrypted: false,
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            axios.defaults.withCredentials = true;
                            axios.post(process.env.REACT_APP_API_URL + '/broadcasting/auth', {
                                socket_id: socketId,
                                channel_name: `private-topic.${this.props.meeting.id}`,
                            })
                                .then(response => {
                                    console.log(response);
                                    callback(false, response.data);
                                })
                                .catch(error => {
                                    console.log(error);
                                    callback(true, error);
                                });
                        }
                    };
                },
            });


            window.echo.private(`topic.${this.props.meeting.id}`)
                .listen('.users.live', function (e) {
                    console.log(e);
                    this.setState({
                        attendees: e.topic.users_meetings,
                        areAttendeesHere: true
                    })
                }.bind(this));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    updateSearch = (data) => {
        this.setState({search: data.target.value})
    }

    sortAttendeesList = () => {
        this.setState({
            sortAttendees: !this.state.sortAttendees,
            selectedSortingButton: !this.state.selectedSortingButton
        })
    }

    dynamicSort = (attendee) => {

        let sortOrder = 1;

        if (attendee[0] === "-") {
            sortOrder = -1;
            attendee = attendee.substr(1);
        }

        if (this.state.sortAttendees) {
            return function (a, b) {
                if (sortOrder == -1) {
                    return a[attendee].localeCompare(b[attendee]);
                } else {
                    return b[attendee].localeCompare(a[attendee]);
                }
            }
        } else {
            return function (a, b) {
                if (sortOrder == -1) {
                    return b[attendee].localeCompare(a[attendee]);
                } else {
                    return a[attendee].localeCompare(b[attendee]);
                }
            }
        }
    }

    filteredAttendeeList = () => {
        return (
            this.state.attendees.filter((attendee) => {
                    return attendee.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                }
            )
        )
    }

    render() {
        const {classes} = this.props;
        return (
            <div className='OrganizerAttendees'>
                <div className='flex align-items__center justify-space-between'>
                    <div className='org-attendee__search'>
                        <Paper className={classes.search}>
                            <InputBase
                                className={classes.input}
                                id="org-search-attendee"
                                size="small"
                                label="Search Attendee"
                                value={this.state.search}
                                onChange={(data) => {
                                    this.updateSearch(data)
                                }}/>
                            <Divider
                                className={classes.divider}
                                orientation="vertical"/>
                            <IconButton
                                className='sort-alpha__attendees-list'
                                aria-label="sort attendee list"
                                value={SortByAlphaIcon}
                                selected={this.state.selectedSortingButton}
                                onClick={() => {
                                    this.sortAttendeesList()
                                }}
                            >
                                <SortByAlphaIcon/>
                            </IconButton>
                        </Paper>
                    </div>
                </div>

                <List className=''>
                    {
                        this.state.areAttendeesHere
                            ?
                            this.state.attendees.length !== 0
                                ?
                                (this.filteredAttendeeList().sort(this.dynamicSort('name'))).map((row) => (
                                    <div key={row.id}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar src={row.image_link}/>
                                            </ListItemAvatar>
                                            <ListItemText color='secondary'>
                                                <Typography className='list-attendee__title live' variant='h6'>
                                                    {row.name}
                                                </Typography>
                                                {/*<Typography className='list-attendee__text' variant='body2'>*/}
                                                {/*    <a href={`mailto:${row.email}`}  style={{color: 'red'}}>*/}
                                                {/*        {row.email}*/}
                                                {/*    </a>*/}
                                                {/*</Typography>*/}
                                                <Typography className='list-attendee__text' variant='body2'>
                                                    {/*<a href={`mailto:${row.email}`}  className='list-attendee__LI-btn'>*/}

                                                    <a href={`https://www.linkedin.com/in/${row.linkedin_url}`}
                                                       target='_blank'
                                                       style={row.linkedin_url ? {} : {cursor: 'not-allowed'}}
                                                       className='list-attendee__LI-btn'>
                                                        <LinkedInIcon/>
                                                        <div className='text'>
                                                            Connect with LinkedIn
                                                        </div>
                                                    </a>

                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </div>
                                ))
                                :
                                <Typography variant='h5' style={{color: '#fafafa'}}>
                                    There are no attendees for this session yet.
                                </Typography>

                            :
                            <Typography variant='h5' style={{color: '#fafafa'}}>
                                {this.state.areAttendeesHere}
                                Waiting for attendees ...
                            </Typography>
                    }
                </List>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // Object.values will returns array of object
        meeting: state.meeting.meeting,
        userData: state.user
    }
}

const mapDispatchToProps = dispatch => (
    {
        joinMeeting: (topic_id) => dispatch(joinMeeting(topic_id)),
    }
)

export default withSanctum(withStyles(styles, {withTheme: true})(connect(
    mapStateToProps,
    mapDispatchToProps
)(ListLiveUsers)));


