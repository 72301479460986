import {
    DRAWER_OPEN,
    DRAWER_CLOSE,
    FETCH_LIVE_TOPICS,
    FETCH_UPCOMING_TOPICS,
    FETCH_SET_TOPIC_MODAL,
    CLOSE_SET_TOPIC_MODAL,
    FETCH_NOT_FOUND_MODAL,
    CLOSE_NOT_FOUND_MODAL,
    FETCH_MY_TOPICS,
    FETCH_LOGIN_MODAL,
    CLOSE_LOGIN_MODAL,
    FETCH_TIME_ZONES,
    FETCH_CONNECTIONS,
    SEARCH_CONNECTIONS,
    FETCH_TOPIC_FEEDBACK_MODAL,
    CLOSE_TOPIC_FEEDBACK_MODAL,
    FETCH_TOPIC_FEEDBACK_RESPONSE_MODAL,
    CLOSE_TOPIC_FEEDBACK_RESPONSE_MODAL,
    CREATE_TOPIC,
    FETCH_CREATE_TOPIC_ERROR,
    FETCH_TOPICS,
    LOGIN,
    LOGOUT,
    UPDATE_PROFILE_ERROR,
    UPDATE_PROFILE,
    FETCH_TAGS,
    UPDATE_TOPIC,
    FETCH_TOPIC_ERROR,
    RETURN_TOPICS_BY_TAG,
    SUBSCRIBE_TOPIC,
    JOIN_MEETING,
    FETCH_CONNECTIONS_ERROR,
    FETCH_UPCOMING_MEETINGS,
    FETCH_UPCOMING_MEETINGS_ERROR,
    FETCH_PAST_MEETINGS,
    FETCH_PAST_MEETINGS_ERROR,
    MEETING_UUID,
    NOTIFY_SUBSCRIBERS_ERROR,
    NOTIFY_SUBSCRIBERS,
    FLAG_TOPIC_ERROR,
    FLAG_TOPIC, THEME, MODAL_LINAME, CLOSE_MODAL_LINAME, MODAL_EMAIL_SUBSCRIBERS, CLOSE_MODAL_EMAIL_SUBSCRIBERS
} from './types';

import streams from "../api/streams";
import timeZones from "../api/timeZones";
import axios from "axios";

//Create Actions

export const login = (data) => {
    return {
        type: LOGIN,
        payload: data
    };
}

export const logOut = (data) => {
    return {
        type: LOGOUT,
        payload: data
    };
}

export const themeToggle = (data) => {
    return {
        type: THEME,
        payload: data
    };
}

export const drawerToggle = (data) => {
    switch (data) {
        case 'drawer-open':
            return {
                type: DRAWER_OPEN,
                data: data
            }
        case 'drawer-close':
            return {
                type: DRAWER_CLOSE,
                data: data
            }
        default:
            return data;
    }
}

export const showModal = (type, data) => {
    switch (type) {
        case 'modal-set-topic':
            return {
                type: FETCH_SET_TOPIC_MODAL,
                content: data,
                modal: type
            }
        case 'modal-login':
            return {
                type: FETCH_LOGIN_MODAL,
                content: data,
                modal: type
            }
        case 'modal-topic-feedback':
            return {
                type: FETCH_TOPIC_FEEDBACK_MODAL,
                content: data,
                modal: type
            }
        case 'modal-feedback-response':
            return {
                type: FETCH_TOPIC_FEEDBACK_RESPONSE_MODAL,
                content: data,
                modal: type
            }
        case 'modal-LIName':
            return {
                type: MODAL_LINAME,
                content: data,
                modal: type
            }
        case 'modal-email-subscribers':
            return {
                type: MODAL_EMAIL_SUBSCRIBERS,
                content: data,
                modal: type
            }
        case 'modal-not-found':
            return {
                type: FETCH_NOT_FOUND_MODAL,
                content: data,
                modal: type
            }
        default:
            return data;
    }
}

export const closeModal = (type, data) => {
    switch (type) {
        case 'modal-set-topic':
            return {
                type: CLOSE_SET_TOPIC_MODAL,
                content: data,
                modal: type
            }
        case 'modal-login':
            return {
                type: CLOSE_LOGIN_MODAL,
                content: data,
                modal: type
            }
        case 'modal-topic-feedback':
            return {
                type: CLOSE_TOPIC_FEEDBACK_MODAL,
                content: data,
                modal: type
            }
        case 'modal-feedback-response':
            return {
                type: CLOSE_TOPIC_FEEDBACK_RESPONSE_MODAL,
                content: data,
                modal: type
            }
        case 'modal-LIName':
            return {
                type: CLOSE_MODAL_LINAME,
                content: data,
                modal: type
            }
        case 'modal-email-subscribers':
            return {
                type: CLOSE_MODAL_EMAIL_SUBSCRIBERS,
                content: data,
                modal: type
            }
        case 'modal-not-found':
            return {
                type: CLOSE_NOT_FOUND_MODAL,
                content: data,
                modal: type
            }
        default:
            return data;
    }
}

export const searchConnections = (data) => {
    return {
        type: SEARCH_CONNECTIONS,
        data: data
    }
}

export const createTopic = (formValues) => async dispatch => {
    axios.defaults.withCredentials = true;

    await streams.post('/api/v3/topics', formValues)
        .then(response => {
            dispatch({
                type: CREATE_TOPIC,
                payload: response.data
            })
        })
        .catch(error => {
            console.log('topic error: ', error.response);
            dispatch({
                type: FETCH_CREATE_TOPIC_ERROR,
                // payload: error.response.data.errors.name[0]
            })
            return error.response
        })
}

export const updateTopic = (formValues, topicId) => async dispatch => {
    axios.defaults.withCredentials = true;

    await streams.post(`/api/v3/topics/${topicId}`, formValues)
        .then(response => {
            dispatch({
                type: UPDATE_TOPIC,
                payload: response.data
            })

        })
        .catch(error => {
            dispatch({
                type: FETCH_TOPIC_ERROR,
                payload: error.response
            })
            console.log('topic error: ', error.response);
            return error.response
        })
}


export const searchTopicsByTagID = (tagId, userId) => async dispatch => {

    await streams.get(`/api/v3/topics?tag=${tagId}`)
        .then(response => {
            dispatch({
                type: FETCH_TOPICS,
                payload: response.data,
                userId: userId
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_TOPIC_ERROR,
                payload: error
            })
            console.log('topic error: ', error.response);
            return error
        })
}

export const searchTopicsByWords = (topicValues) => async dispatch => {

    await streams.get(`api/v3/topics?search=${topicValues}`)
        .then(response => {
            dispatch({
                type: FETCH_TOPICS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_TOPIC_ERROR,
                payload: error
            })
            console.log('topic error: ', error);
            return error.response
        })
}


export const fetchTopics = (userId) => async dispatch => {
    await streams.get('/api/v3/topics')
        .then(response => {
            dispatch({
                type: FETCH_TOPICS,
                payload: response.data,
                userId: userId
            })
        })
}

export const fetchUpcomingMeetings = () => async dispatch => {
    await streams.get('/api/v3/dashboard/upcoming-meetings')
        .then(response => {
            dispatch({
                type: FETCH_UPCOMING_MEETINGS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_UPCOMING_MEETINGS_ERROR,
                payload: error
            })
            console.log('upcoming meeting error: ', error.response.data.message);
            return error.response
        })
}

export const fetchPastMeetings = () => async dispatch => {
    await streams.get('/api/v3/dashboard/past-meetings')
        .then(response => {
            dispatch({
                type: FETCH_PAST_MEETINGS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_PAST_MEETINGS_ERROR,
                payload: error
            })
            console.log('upcoming meeting error: ', error.response.data.message);
            return error.response
        })
}

export const subscribeTopic = (topic_id) => async dispatch => {
    await streams.post('/api/v3/subscribe', topic_id)
        .then(response => {
            dispatch({
                type: SUBSCRIBE_TOPIC,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_TOPIC_ERROR,
                payload: error
            })
            console.log('topic error: ', error.response);
            return error.response
        })
}


export const joinMeeting = (topic_id) => async dispatch => {
    await streams.post('/api/v3/join-meeting', topic_id)
        .then(response => {
            dispatch({
                type: JOIN_MEETING,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_TOPIC_ERROR,
                payload: error
            })
            console.log('topic error: ', error.response);
            return error.response
        })
}

export const notifySubscribers = (topic_id) => async dispatch => {
    axios.defaults.withCredentials = true;

    await streams.post('/api/v3/notify-subscribers', topic_id)
        .then(response => {
            dispatch({
                type: NOTIFY_SUBSCRIBERS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: NOTIFY_SUBSCRIBERS_ERROR,
                payload: error
            })
            console.log('notify subscribers error: ', error.response);
            return error.response
        })
}

export const flag = (formValues) => async dispatch => {
    axios.defaults.withCredentials = true;

    await streams.post('/api/v3/flag', formValues)
        .then(response => {
            dispatch({
                type: FLAG_TOPIC,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: FLAG_TOPIC_ERROR,
                payload: error
            })
            console.log('flag error: ', error.response);
            return error.response
        })
}

export const dispatchMeetingUUId = (data) => {
    return {
        type: MEETING_UUID,
        data: data
    }
}


export const fetchTags = () => async dispatch => {
    await streams.get('/api/v3/tags')
        .then(response => {
            dispatch({
                type: FETCH_TAGS,
                payload: response.data
            })
        })
}

export const fetchLiveTopics = () => async dispatch => {
    const response = await timeZones.get('/liveTopics.json');
    dispatch({type: FETCH_LIVE_TOPICS, payload: response.data})
}
export const fetchUpcomingTopics = () => async dispatch => {
    const response = await timeZones.get('/upcomingTopics.json');
    dispatch({type: FETCH_UPCOMING_TOPICS, payload: response.data})
}

export const fetchMyTopics = (topics, userId) => {
    return {type: FETCH_MY_TOPICS, topics: topics, userId: userId}
}

export const fetchTimeZones = () => async dispatch => {
    const response = await timeZones.get('/timeZones.json');
    dispatch({type: FETCH_TIME_ZONES, payload: response.data})
}
export const fetchConnections = () => async dispatch => {
    await streams.get('api/v3/connections')
        .then(response => {
            dispatch({
                type: FETCH_CONNECTIONS,
                payload: response.data
            })

        })
        .catch(error => {
            dispatch({
                type: FETCH_CONNECTIONS_ERROR,
                payload: error.response
            })
            console.log('user connections error: ', error.response.data.message);
            return error.response
        })
}

// USER PROFILE
export const updateProfile = (formValues) => async dispatch => {
    axios.defaults.withCredentials = true;

    await streams.post('api/v3/profile', formValues)
        .then(response => {
            dispatch({
                type: UPDATE_PROFILE,
                payload: response.data
            })

        })
        .catch(error => {
            dispatch({
                type: UPDATE_PROFILE_ERROR,
                payload: error.response
            })
            return error.response.data.message
        })
}
