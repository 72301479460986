import {
    FETCH_LIVE_TOPICS,
    FETCH_UPCOMING_TOPICS,
    FETCH_MY_TOPICS, CREATE_TOPIC, FETCH_TOPICS, LOGIN, SUBSCRIBE_TOPIC
} from '../actions/types'
import {DateTime} from "luxon";


let INITIAL_STATE = {
    liveTopics: null,
    upcomingTopics: null,
    openLiveTopics: null,
    myTopics: null,
    createdTopic: {},
    topics: null,
    userId: null,
    subscribeTopics: null
}

const isDateNow = (startAt, endAt) => {
    // GET DATES BY TIMESTAMPS
    const startDate = DateTime.fromISO(startAt).ts
    const endDate = DateTime.fromISO(endAt).ts
    const now = Number(DateTime.local().toFormat('x'))

    return startDate < now && now < endDate
}


// 'topics' are the all topics with converted date time to the device timezone
//this 'topics' function is mapping over topics, convert timezone and return new array
const topics = (list, startAt, endAt) => {
    return list.map(item => {
        const obj = Object.assign({}, item);
        const defaultCurrentTimeZone = DateTime.local().zoneName;
        obj[startAt] = DateTime.fromSQL(obj[startAt]).setZone("UTC", {keepLocalTime: true}).setZone(defaultCurrentTimeZone).toString();
        obj[endAt] = DateTime.fromSQL(obj[endAt]).setZone("UTC", {keepLocalTime: true}).setZone(defaultCurrentTimeZone).toString();
        return obj;
    });
}

const myTopics = (topics, userId) => {
    let myTopics = []

    if (userId && topics) {

        topics.map(topic => {
            if (topic.host.id === userId) {
                myTopics.push(topic)
            }
        })
        return myTopics
    }
}

const mostRatedTopics = (topics) => {
    let sortedTopics = []
    let mostRatedTopics = []

    mostRatedTopics = topics.sort((a, b) => b.popularity - a.popularity)

    return mostRatedTopics

}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_TOPIC:
            return {...state, createdTopic: action.payload}
        case FETCH_TOPICS:
            // console.log(action);

            let allTopicsArr = topics(action.payload.data, 'start_at', 'end_at')
            let openLiveTopicsArr = []
            let myTopicsArr = []
            let upcomingTopicsArr = []
            let mostRatedTopicsArr = []
            let liveTopicsArr = []
            //subscribed topics
            let subscribeTopicsArrOpenLive = []
            let subscribeTopicsArrLive = []
            let subscribeTopicsArrUpcoming = []
            let subscribeTopicsArrMostRated = []
            if (allTopicsArr.length > 0) {
                allTopicsArr.map((topic) => {

                    if (topic.subscribed) {
                        if (isDateNow(topic.start_at, topic.end_at)) {
                            subscribeTopicsArrLive.push(topic)
                            if (topic.popularity < 35) {
                                subscribeTopicsArrOpenLive.push(topic)
                            }
                        } else {
                            subscribeTopicsArrUpcoming.push(topic)
                            subscribeTopicsArrMostRated.push(topic)
                        }
                    }

                    if (isDateNow(topic.start_at, topic.end_at)) {
                        liveTopicsArr.push(topic)
                        if (topic.popularity < 35) {
                            openLiveTopicsArr.push(topic)
                        }
                    } else {
                        upcomingTopicsArr.push(topic)
                        mostRatedTopicsArr.push(topic)
                    }
                    if (topic.host.id === action.userId) {
                        myTopicsArr.push(topic)
                    }
                })

            }
            return {
                ...state,
                topics: allTopicsArr,
                userId: action.userId,
                myTopics: {
                    title: "My topics",
                    tabs: [
                        {
                            id: 1,
                            title: "All",
                            topics: myTopicsArr
                        }
                    ]
                },
                liveTopics: {
                    title: "Live Now",
                    tabs: [
                        {
                            id: 1,
                            title: "All",
                            topics: liveTopicsArr
                        },
                        {
                            id: 2,
                            title: "Open",
                            topics: openLiveTopicsArr
                        }
                    ]
                },
                upcomingTopics: {
                    title: "Upcoming",
                    tabs: [
                        {
                            id: 1,
                            title: "By Date",
                            topics: upcomingTopicsArr
                        },
                        {
                            id: 2,
                            title: "By Popularity",
                            topics: mostRatedTopics(mostRatedTopicsArr)
                        }
                    ]
                },
                subscribeTopics: {
                    liveTopics: {
                        title: "Live Now",
                        tabs: [
                            {
                                id: 1,
                                title: "All",
                                topics: subscribeTopicsArrLive
                            },
                            {
                                id: 2,
                                title: "Open",
                                topics: subscribeTopicsArrOpenLive
                            }
                        ]
                    },
                    upcomingTopics: {
                        title: "Upcoming",
                        tabs: [
                            {
                                id: 1,
                                title: "By Date",
                                topics: subscribeTopicsArrUpcoming
                            },
                            {
                                id: 2,
                                title: "By Popularity",
                                topics: mostRatedTopics(subscribeTopicsArrUpcoming)
                            }
                        ]
                    }
                }
            }
        case FETCH_LIVE_TOPICS:
            return {...state, liveTopics: action.payload}
        case FETCH_UPCOMING_TOPICS:
            return {...state, upcomingTopics: action.payload}
        case FETCH_MY_TOPICS:
            return {...state, myTopics: myTopics(action.topics, action.userId)}
        case SUBSCRIBE_TOPIC:
            return {...state, subscribeTopics: action.payload}
        default:
            return state;
    }
}
