import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {
    Button,
    DialogContent
} from "@material-ui/core";
import {withRouter} from "react-router";
import {closeModal} from "../../actions";
import Typography from "@material-ui/core/Typography";
import LogoInit from "../Logo/LogoInit";
import {blue} from "@material-ui/core/colors";

const styles = theme => ({
    buttonSubmit: {
        margin: '5px',
        padding: '5px 25px',
        backgroundColor: blue[500],
        color: theme.palette.light.default,
        '&:hover': {
            backgroundColor: blue[300],
        }
    },
    buttonCancel: {
        margin: '5px',
        padding: '5px 25px',
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            padding: 0
        },
        '& textarea': {
            padding: '1rem 1.5rem'
        },
        '& textarea:focus + fieldset': {
            borderColor: `#ffffff !important`,
            borderWidth: 2,
        },
        '& textarea:focus:hover + fieldset': {
            borderColor: `#ffffff !important`,
            borderWidth: 2,
        }
    }
});


class ModalTopicFeedbackResponse extends Component {

    state = {
    }

    componentDidMount() {
        
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
        this.props.closeModal('modal-feedback-response')
    };



    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <DialogContent
                    className='modal__topic-feedback'>

                    <div style={{textAlign: 'center', margin: '20px 20px 30px'}}>
                        {/*SPARCMORE LOGO*/}
                        <LogoInit variant={'h4'}/>
                        {/* !SPARCMORE LOGO*/}
                    </div>

                    <Typography variant='body1'>
                        Thank you for helping to keep SPARCmore professional and respectful so that
                        meaningful conversations and communities can flourish.
                    </Typography>

                    <Typography style={{marginTop: '50px', marginBottom: '50px', textAlign: 'center'}} variant='h6'>
                        Your submission was received by our team. Thank you!
                    </Typography>


                    <div className='modal__topic-feedback__actions'>
                        <Button
                            variant="contained"
                            className={classes.buttonSubmit}
                            onClick={this.closeModal}
                        >
                            Close
                        </Button>
                    </div>

                </DialogContent>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

const mapDispatchToProps = dispatch => (
    {
        closeModal: data => dispatch(closeModal(data))
    }
)

export default withRouter(withStyles(styles,
    {
        withTheme: true
    }
)(connect(mapStateToProps, mapDispatchToProps)(ModalTopicFeedbackResponse)));
