import React, {Component} from "react";
import 'fontsource-roboto';
import {fetchConnections, fetchPastMeetings, fetchUpcomingMeetings} from "../../actions";
import {connect} from 'react-redux'
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import DashboardCard from "../dashboardCards/DashboardCard";

const styles = theme => ({
    dashTitleCard: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        '& .MuiCardContent-root:last-child': {
            padding: '16px'
        }
    }
});

class Dashboard extends Component {

    state = {
        upcomingMeetings: null,
        pastMeetings: null
    }

    fetchMeetings = () => {
        if (this.props?.upcomingMeetings && this.props?.pastMeetings &&
            this.props.upcomingMeetings !== this.state.upcomingMeetings &&
            this.props.pastMeetings !== this.state.pastMeetings) {
            this.setState({
                upcomingMeetings: this.props.upcomingMeetings,
                pastMeetings: this.props.pastMeetings
            })
        }
    }

    componentDidMount() {
        this.fetchMeetings()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.fetchMeetings()
    }

    render() {
        const {classes} = this.props;
        return (
            <div className='dashboard__wrap'>
                <div className={'dashboard__wrap__section'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card className={classes.dashTitleCard}>
                                <CardContent>
                                    <Typography variant='h5'>
                                        Your UPCOMING conversation rooms
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        {
                            this.state.upcomingMeetings
                                ?
                                this.state.upcomingMeetings.map((topic, index) => {
                                    return (
                                        <Grid item key={topic.title} xs={6}>
                                            <DashboardCard topic={topic} type={'upcoming meeting'}/>
                                        </Grid>
                                    )
                                })
                                :
                                <Typography>
                                    Loading...
                                </Typography>
                        }
                    </Grid>
                </div>
                <div className={'dashboard__wrap__section'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card className={classes.dashTitleCard}>
                                <CardContent>
                                    <Typography variant='h5'>
                                        Your PAST conversation rooms
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        {
                            this.state.pastMeetings
                                ?
                                this.state.pastMeetings.map((topic, index) => {
                                    return (
                                        <Grid item key={topic.title} xs={6}>
                                            <DashboardCard topic={topic} type={'past meeting'}/>
                                        </Grid>
                                    )
                                })
                                :
                                <Typography>
                                    Loading...
                                </Typography>
                        }
                    </Grid>
                </div>

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        upcomingMeetings: state.upcomingMeetings.data,
        pastMeetings: state.pastMeetings.data
    };
}


const mapDispatchToProps = dispatch => ({})

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(Dashboard))
