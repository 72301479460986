import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {
    Button,
    DialogContent, Link, TextField
} from "@material-ui/core";
import {withRouter} from "react-router";
import {closeModal, updateProfile} from "../../actions";
import Typography from "@material-ui/core/Typography";
import LogoInit from "../Logo/LogoInit";
import {blue} from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";

const styles = theme => ({
    buttonSubmit: {
        margin: '5px',
        padding: '5px 25px',
        backgroundColor: blue[500],
        color: theme.palette.light.default,
        '&:hover': {
            backgroundColor: blue[300],
        }
    },
    buttonCancel: {
        margin: '5px',
        padding: '5px 25px',
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            padding: 0
        },
        '& textarea': {
            padding: '1rem 1.5rem'
        },
        '& textarea:focus + fieldset': {
            borderColor: `#ffffff !important`,
            borderWidth: 2,
        },
        '& textarea:focus:hover + fieldset': {
            borderColor: `#ffffff !important`,
            borderWidth: 2,
        }
    },
    link: {
        color: theme.palette.link.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
});


class ModalAddLIName extends Component {

    state = {
        user: null,
        linkedin_url: ''
    }

    componentDidMount() {
        console.log(this.props.tz);
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
        this.props.closeModal('modal-LIName')
    };


    submitProfileData = () => {
        this.props.updateProfile({
            social_account_url: this.state.linkedin_url,
            provider_name: 'linkedin',
            timezone: this.props.modal.data.content.timezone ? this.props.modal.data.content.timezone : this.props.tz.currentTimeZone
        })
        .then(() => {
            window.location.reload();
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    updateLI = (e) => {
        this.setState({
            linkedin_url: e.target.value
        })
    }

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <DialogContent
                    className='modal__topic-feedback'>

                    <div style={{textAlign: 'center', margin: '20px 20px 30px'}}>
                        {/*SPARCMORE LOGO*/}
                        <LogoInit variant={'h4'}/>
                        {/* !SPARCMORE LOGO*/}
                    </div>

                    <Typography variant='h6'>
                        Please enter your LinkedIn URL so that others on SPARCmore can connect
                        with you.
                    </Typography>

                    <div style={{marginTop: '3.125rem'}}>
                        <Typography variant='h6' className='mt-24'>
                            <Link className={classes.link}
                                  href='#'>
                                https://www.linkedin.com/in/
                            </Link>
                            <TextField
                                onChange={this.updateLI}
                                value={this.state.linkedin_url}
                                style={{fontSize: '20px'}}
                                id="liURL"/>
                        </Typography>

                    </div>

                    <div className='modal__topic-feedback__actions'>
                        <Button
                            variant="contained"
                            className={classes.buttonSubmit}
                            disabled={!this.state.linkedin_url}
                            onClick={() => {
                                this.submitProfileData()
                            }}
                        >
                            update profile information
                        </Button>
                    </div>

                </DialogContent>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        tz: state.tz
    }
}

const mapDispatchToProps = dispatch => ({
    closeModal: data => dispatch(closeModal(data)),
    updateProfile: data => dispatch(updateProfile(data))
})

export default withRouter(withStyles(styles,
    {
        withTheme: true
    }
)(connect(mapStateToProps, mapDispatchToProps)(ModalAddLIName)));
