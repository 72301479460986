import React, {Component} from "react";
import 'fontsource-roboto';
import {withStyles} from "@material-ui/core/styles";
import { List, ListItem, ListItemText
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    textField: {
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#ffffff'
        }
    },
    spanNums: {
        color: theme.palette.secondary.main,
        background: '#ffffff',
        borderRadius: '5px',
        padding: '2px',
        fontWeight: 'bold',
        marginLeft: '10px'
    }
});


class DashboardList extends Component {

    state = {}


    render() {
        const {classes} = this.props;
        return (
            <List>

                <ListItem>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    variant="body1"
                                >
                                    # Subscribers - Last 3 days: <span className={classes.spanNums}>10</span>
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    variant="body1"
                                >
                                    # Subscribers - Last 7 days: <span className={classes.spanNums}>10</span>
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography
                                    variant="body1"
                                >
                                    # Total subscribers to date: <span className={classes.spanNums}>20</span>
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </List>
        )
    }

}

export default withStyles(styles, {withTheme: true})(DashboardList);
