import React, {Component} from "react";
import 'fontsource-roboto';
import ScrollableTabs from "../scrollableTabs/ScrollableTabs";
import WelcomeSection from "../welcomeSection/WelcomeSection";
import TopicsSection from "../topicsSection/TopicsSection";
import {fetchLiveTopics, fetchUpcomingTopics} from "../../actions";
import {connect} from 'react-redux'
import {
    Typography
} from "@material-ui/core";
import TextFieldCreateTopic from "../textFieldCreateTopic/ТextFieldCreateTopic";
import {withSanctum} from "react-sanctum";
import {withRouter} from "react-router";

class SubscribedPage extends Component {

    state = {
        liveTopics: null,
        upcomingTopics: null
    }

    fetchTopics = () => {
        if (this.props.subscribeTopics?.liveTopics && this.props.subscribeTopics.liveTopics !== this.state.liveTopics) {
            this.setState({liveTopics: this.props.subscribeTopics.liveTopics})

        }

        if (this.props.subscribeTopics?.upcomingTopics && this.props.subscribeTopics.upcomingTopics !== this.state.upcomingTopics) {
            this.setState({upcomingTopics: this.props.subscribeTopics.upcomingTopics})
        }
    }

    componentDidMount() {
        this.fetchTopics()
    }

    componentDidUpdate() {
        this.fetchTopics()
    }

    render() {
        return (
            <div>
                <div className='mt-24'>
                    {
                        this.state.liveTopics
                            ?
                            <TopicsSection topicsSection={this.state.liveTopics}/>
                            :
                            <Typography>
                                Loading ...
                            </Typography>
                    }
                    {

                        this.state.upcomingTopics
                            ?

                            <TopicsSection topicsSection={this.state.upcomingTopics}/>
                            :
                            <Typography>
                                Loading ...
                            </Typography>
                    }
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        subscribeTopics: state.topics.subscribeTopics
    };
}

export default connect(
    mapStateToProps,
    {
        fetchLiveTopics,
        fetchUpcomingTopics
    }
)(withSanctum(withRouter(SubscribedPage)));
