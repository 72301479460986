import React, {Component} from "react";
import 'fontsource-roboto';
import {withStyles} from "@material-ui/core/styles";
import {
    Button,
    Card,
    CardContent,
    Grid, List, ListItem, ListItemText, TextField
} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";
import DashboardList from "../list/DashboardList";
import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {DateTime} from "luxon";
import {joinMeeting, notifySubscribers, showModal} from "../../actions";
import {withRouter} from "react-router";
import {withSanctum} from "react-sanctum";

const styles = theme => ({
    textField: {
        '& .MuiInputBase-input.Mui-disabled': {
            color: '#ffffff'
        }
    },
    buttonPrimary: {
        margin: '5px',
        padding: '5px 25px',
        backgroundColor: blue[500],
        color: theme.palette.light.default
    }
});


class DashboardCard extends Component {

    state = {
        topic: null,
        type: this.props.type,
        user: null,
        localTimeZone: null
    }

    fetchTopic = () => {
        if (this.props?.topic && this.props.topic !== this.state.topic) {
            this.setState({
                topic: this.props.topic
            })
        }
    }

    componentDidMount() {
        this.fetchTopic()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.fetchTopic()
        if (this.props?.user && this.state.user !== this.props.user){
            this.setState({
                user: this.props.user,
                localTimeZone : this.props.user.timezone ? this.props.user.timezone :  DateTime.local().zoneName
            })
        }
    }

    onNotifySubscribers = () => {
        this.props.notifySubscribers({topic_id: Number(this.state.topic.id)})
            .then(()=>{
                this.props.showModal('modal-email-subscribers', '')
            })
    }

    convertDate = (date) => {
        return DateTime.fromSQL(date).setZone("UTC", {keepLocalTime: true}).setZone(this.state.localTimeZone).toLocaleString(DateTime.TIME_SIMPLE)
    }

    render() {
        const {classes} = this.props;


        return (
            <Card className={'card-dashboard'}>
                {
                    this.state.topic
                        ?
                        <CardContent>
                            <Grid
                                container
                                spacing={5}
                                className='modal__set-topic__content__wrap'
                            >
                                <Grid item xs={12}>
                                    <Card variant={"outlined"}>
                                        <CardContent>
                                            {this.state.topic.title}
                                        </CardContent>
                                    </Card>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid item md={4}>
                                            <TextField
                                                disabled
                                                className={classes.textField}
                                                id={`${this.state.topic.id}_date`}
                                                label="Date"
                                                value={DateTime.fromSQL(this.state.topic.start_at).toLocaleString(DateTime.DATE_MED)}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <TextField
                                                disabled
                                                className={classes.textField}
                                                id={`${this.state.topic.id}_start-time`}
                                                label="Start time"
                                                value={this.convertDate(this.state.topic.start_at)}
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <TextField
                                                disabled
                                                className={classes.textField}
                                                id={`${this.state.topic.id}_end-time`}
                                                label="End time"
                                                value={this.convertDate(this.state.topic.end_at)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                className='modal__set-topic__content__wrap mt-2 mb-24'
                            >
                                <Grid item xs={6}>
                                    {
                                        this.state.type === 'upcoming meeting'
                                            ?
                                            <List>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    variant="body1"
                                                                >
                                                                    # Subscribers - Last 3 days: <span
                                                                    className={classes.spanNums}>{this.state.topic.subscribers_last_three_days_count}</span>
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    variant="body1"
                                                                >
                                                                    # Subscribers - Last 7 days: <span
                                                                    className={classes.spanNums}>{this.state.topic.subscribers_last_seven_days_count}</span>
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    variant="body1"
                                                                >
                                                                    # Total subscribers to date: <span
                                                                    className={classes.spanNums}>{this.state.topic.total_subscribers_to_date}</span>
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                            :
                                            <List>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    variant="body1"
                                                                >
                                                                    # Total subscribers: <span
                                                                    className={classes.spanNums}>{this.state.topic.total_subscribers}</span>
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    variant="body1"
                                                                >
                                                                    # LI connections: <span
                                                                    className={classes.spanNums}>{this.state.topic.li_connections}</span>
                                                                </Typography>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                    }

                                </Grid>
                                <Grid item xs={6}>
                                    <div className='modal__set-topic__control-buttons'>
                                        <div>

                                        </div>
                                        <div>
                                            {
                                                this.state.type === 'upcoming meeting'
                                                    ?
                                                    <Button
                                                        variant="contained"
                                                        className={classes.buttonPrimary}
                                                        onClick={() => {
                                                            this.onNotifySubscribers()
                                                        }}
                                                    >
                                                        EMAIL SUBSCRIBERS
                                                    </Button>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                        :
                        null
                }
            </Card>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    };
}

const mapDispatchToProps = dispatch => ({
    notifySubscribers: (topic_id) => dispatch(notifySubscribers(topic_id)),
    showModal: (type, data) => dispatch(showModal(type, data)),
})

export default withStyles(styles, {withTheme: true})(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withSanctum(withRouter(DashboardCard)))));
