import {
    LOGIN,
    LOGOUT
} from '../actions/types'
import {DateTime} from "luxon";

let INITIAL_STATE = {
    data: {},
    isLogIn: false,
    error: {},
    currentTimeZone: DateTime.local().zoneName
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN:
            return {...state, data: action.payload, isLogIn: true}
        case LOGOUT:
            return {...state, data: action.payload, isLogIn: false}
        default:
            return state;
    }
}
