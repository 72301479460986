import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {closeModal} from "../../actions";
import {connect} from "react-redux";
import {
    Dialog
} from "@material-ui/core";
import {withRouter} from "react-router";
import ModalSetTopic from "./ModalSetTopic";
import ModalNotFound from "./ModalNotFound";
import ModalLogin from "./ModalLogin";
import ModalTopicFeedback from "./ModalTopicFeedback";
import ModalTopicFeedbackResponse from "./ModalTopicFeedbackResponse";
import ModalAddLIName from "./ModalAddLIName";
import ModalEmailSubscribers from "./ModalEmailSubscribers";

const styles = theme => ({});

class ModalContent extends Component {

    state = {
        modalIsOpen: this.props.modal.modalIsOpen,
        modalContent: this.props.modal.data,
        modalFullWidth: this.props.modal.fullWidth,
        modalMaxWidth: this.props.modal.maxWidth
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
        this.props.closeModal(this.state.modalContent.modal)
        if (localStorage.getItem("topic_title") !== null) {
            localStorage.removeItem('topic_title');
        }
    };

    componentDidMount() {
        this.setState({
            modalIsOpen: this.props.modal.modalIsOpen,
            modalContent: this.props.modal.data,
            modalFullWidth: this.props.modal.fullWidth,
            modalMaxWidth: this.props.modal.maxWidth
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.modalIsOpen !== this.props.modal.modalIsOpen || this.state.modalContent !== this.props.modal.data) {
            this.setState({
                modalIsOpen: this.props.modal.modalIsOpen,
                modalContent: this.props.modal.data,
                modalFullWidth: this.props.modal.fullWidth,
                modalMaxWidth: this.props.modal.maxWidth
            })
        }
    }


    returnContent = (content) => {
        if (content && content.modal) {
            switch (content.modal) {
                case 'modal-set-topic':
                    return <ModalSetTopic/>
                case 'modal-login':
                    return <ModalLogin/>
                case 'modal-topic-feedback':
                    return <ModalTopicFeedback/>
                case 'modal-feedback-response':
                    return <ModalTopicFeedbackResponse/>
                case 'modal-LIName':
                    return <ModalAddLIName/>
                case 'modal-email-subscribers':
                    return <ModalEmailSubscribers/>
                case 'modal-not-found':
                    return <ModalNotFound/>
            }
        }

    }

    render() {

        return (
            <Dialog
                fullWidth={this.state.modalFullWidth}
                maxWidth={this.state.modalMaxWidth}
                open={this.state.modalIsOpen}
                onClose={this.closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {this.returnContent(this.state.modalContent)}
            </Dialog>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        modal: state.modal
    }
}

const mapDispatchToProps = dispatch => ({
    closeModal: data => dispatch(closeModal(data))
})

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(ModalContent)));
