import React, {Component} from 'react';
import '../assets/styles/main.css';
import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import NotFound from "./pages/NotFound";
import history from "../history";
import {Route, Switch} from "react-router-dom";
import Header from "./header/Header";
import {
    AppBar,
    createMuiTheme,
    ThemeProvider
} from '@material-ui/core';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";
import DrawerContent from "./drawer/DrawerContent";
import MyConversationsPage from "./pages/MyConversationsPage";
import ModalContent from "./modals/ModalContent";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ConnectionsPage from "./pages/ConnectionsPage";
import Dashboard from "./pages/Dashboard";
import {withSanctum} from "react-sanctum";
import {
    fetchConnections,
    fetchTags,
    fetchTopics,
    login,
    logOut,
    showModal,
    fetchUpcomingMeetings,
    fetchPastMeetings
} from "../actions";
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router";
import SubscribedPage from "./pages/SubscribedPage";
import Meeting from "./pages/Meeting";


const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },

    overrides: {
        MuiButton: {
            root: {
                fontSize: '1rem',
            },
        }
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth
    },

    content: {
        // flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },

});

class App extends Component {

    state = {
        drawerIsOpen: this.props.drawer.drawerIsOpen,
        //true is the dark
        theme: null,
        isAuth: false,
        user: this.props.userData,
        isAuthChecked: false
    }


    componentDidMount() {
        this.checkAuth();
        this.fetchPriority();
    }

    fetchPriority = () => {
        this.props.fetchTags();
        this.props.fetchConnections();
        this.props.fetchUpcomingMeetings();
        this.props.fetchPastMeetings();
    }

    checkNavigation = () => {
        if (!this.state.user.isLogIn && this.props.location.pathname !== '/') {
            history.push('/')
            this.props.showModal('modal-login', '')
        }
    }

    checkAuth = () => {
        this.props.checkAuthentication()
            .then((response) => {
                this.setState({
                    isAuth: response,
                    //this shows if we already get the response from checkAuthentication()
                    isAuthChecked: true
                })
                if (response) {
                    this.props.login(this.props.user)
                } else {
                    this.props.logOut()
                }
            })
            .then(() => {
                this.checkNavigation();
            })
            .then(() => {
                // console.log(this.state.user.isLogIn);
                if (this.state.user.isLogIn) {
                    this.props.fetchTopics(this.state.user.data.id)
                    if (!this.state.user.data.linkedin_url){
                        this.props.showModal('modal-LIName', this.state.user.data)
                    }
                } else {
                    this.props.fetchTopics()
                }

            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.drawer.drawerIsOpen !== this.state.drawerIsOpen) {
            this.setState({drawerIsOpen: this.props.drawer.drawerIsOpen})
        }

        if (this.props.userData && this.props.userData !== this.state.user) {
            this.setState({user: this.props.userData})
        }

        if (this.props?.theme && this.props.theme.data !== this.state.theme) {
            this.setState({theme: this.props.theme.data})
        }
        // console.log(this.props.theme);
    }

    unAuthorizedLayout = () => {
        return (
            <Switch>
                <Route path='/' exact component={HomePage}/>
                <Route path='/terms-of-use' exact component={TermsOfUse}/>
                <Route path='/privacy-policy' exact component={PrivacyPolicy}/>
                <Route path='*' component={NotFound}/>
            </Switch>
        )
    }

    authorizedLayout = () => {
        return (
            <Switch>
                <Route path='/' exact component={HomePage}/>
                <Route path='/subscribed' exact component={SubscribedPage}/>
                <Route path='/profile' exact component={ProfilePage}/>
                <Route path='/conversations' exact component={MyConversationsPage}/>
                <Route path='/connections' exact component={ConnectionsPage}/>
                <Route path='/dashboard' exact component={Dashboard}/>
                <Route path='/meeting/:uuid' exact component={Meeting}/>
                <Route path='/terms-of-use' exact component={TermsOfUse}/>
                <Route path='/privacy-policy' exact component={PrivacyPolicy}/>
                <Route path='*' component={NotFound}/>
            </Switch>
        )
    }

    handleLayout = () => {

        if (this.state.isAuthChecked) {
            if (this.state.isAuth) {
                return this.authorizedLayout()
            } else {
                return this.unAuthorizedLayout()
            }
        } else {
            return (
                <Typography>
                    Loading ...
                </Typography>
            )
        }

    }

    logout = () => {
        this.props.signOut().then(() => {
            this.props.checkAuthentication().then((response) => {
                if (!response) {
                    history.push('/')
                    window.location.reload();
                }
            })
        })
    }

    // handleActions = (actions) => {
    //     if (actions.type === 'logout') {
    //         this.logout()
    //     } else if (actions.type === 'showLoginModal') {
    //         this.props.showModal('modal-login', '')
    //     }
    // }


    render() {
        const themeLight = createMuiTheme({
            palette: {
                type: 'light',
                primary: {
                    main: '#ffffff'
                },
                secondary: {
                    main: '#ee220c'
                },
                link: {
                    main: '#1E1E1E'
                },
                background: {
                    default: '#ffffff'
                },
                light: {
                    default: '#ffffff'
                },
                lightDimmer: {
                    default: '#9d9d9d'
                }
            }
        });
        const themeDark = createMuiTheme({
            palette: {
                type: 'dark',
                primary: {
                    main: '#1E1E1E'
                },
                secondary: {
                    main: '#FE311B',
                },
                link: {
                    main: '#ffffff',
                },
                background: {
                    default: '#141414'
                },
                light: {
                    default: '#ffffff'
                },
                lightDimmer: {
                    default: '#9d9d9d'
                }
            },
            overrides: {
                MuiPickersToolbar: {
                    toolbar: {
                        backgroundColor: '#ee220c',
                    },
                },
                MuiPickersCalendarHeader: {
                    switchHeader: {
                        // backgroundColor: lightBlue.A200,
                        // color: "white",
                    },
                },
                MuiPickersDay: {
                    day: {
                        color: '#f5f5f5',
                    },
                    daySelected: {
                        backgroundColor: 'rgba(238,34,12, 1)',
                    },
                    current: {
                        color: '#ffffff',
                    },
                },
                MuiPickersModal: {
                    dialogAction: {
                        color: '#ffffff',
                    },
                },
                MuiCssBaseline: {
                    '@global': {
                        '*': {
                            'scrollbar-width': 'thin',
                            'scrollbar-color': '#ee220c #1E1E1E',
                        },
                        '*::-webkit-scrollbar': {
                            width: '5px',
                            height: '5px',
                        },
                        '*::-webkit-scrollbar-thumb': {
                            background: '#ee220c',
                            borderRadius: '5px'
                        }
                    }
                }
            },
        });

        const {classes} = this.props;
        return (

            <ThemeProvider theme={this.state.theme ? themeLight : themeDark}>
                <CssBaseline/>

                <div className="wrapper">
                    <AppBar position="fixed" className={classes.appBar}>
                        {/*HEADER*/}
                        <Header/>
                        {/*!HEADER*/}
                    </AppBar>

                    {/*!DRAWER DIALOG*/}
                    <DrawerContent/>
                    {/*!DRAWER DIALOG*/}

                    <main className={clsx(classes.content, {
                        // THE MAIN SECTION NEEDS FROM DRAWER STATE
                        [classes.contentShift]: this.state.drawerIsOpen,
                    })}>
                        <Toolbar/>
                        {this.handleLayout()}
                    </main>
                </div>

                {/*MODAL DIALOG*/}
                <ModalContent/>
                {/*!MODAL DIALOG*/}

            </ThemeProvider>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        drawer: state.drawer,
        userData: state.user,
        theme: state.theme
    };
}

const mapDispatchToProps = dispatch => ({
    // drawerToggle: data => dispatch(drawerToggle(data)),
    login: data => dispatch(login(data)),
    logOut: () => dispatch(logOut()),
    showModal: (type, data) => dispatch(showModal(type, data)),
    fetchTopics: (data) => dispatch(fetchTopics(data)),
    fetchTags: () => dispatch(fetchTags()),
    fetchConnections: () => dispatch(fetchConnections()),
    fetchUpcomingMeetings: () => dispatch(fetchUpcomingMeetings()),
    fetchPastMeetings: () => dispatch(fetchPastMeetings())
})

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(withSanctum(withRouter(App))));
